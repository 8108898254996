
.App {
    max-width: 1200px;
    margin-top: 10px;
    margin-bottom: 0px;
    margin-left: auto;
    margin-right: auto;
}

.code {
    font-family: source-code-pro, Menlo, Monaco, 'Fira Code', Consolas, 'Courier New', monospace !important;
}

.diagramParams {
    margin-bottom: 1rem;
}

#root {
    overflow: hidden;
}

.zenMode .MainPanel {
    position: fixed;
    left: 0px;
    top: 0px;
    bottom: 0px;
    right: 0px;
}

.zenMode .NonZen {
    display: none;
}